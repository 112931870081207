import { LPTokenState, TokenState } from '../store/lib/TokenState';
import { ContractState, AeolusContractState } from '../store/lib/ContractState';
import AeolusV2ABI from '../../public/contracts/AeolusV2.json';
import PancakePairABI from '../../public/contracts/pancakePair.json';
import XRC20ABI from '../../public/contracts/XRC20.json';
import CYCLONEABI from '../../public/contracts/IOTXCyclone.json';
import { PoolState } from '../store/lib/PoolState';
import { ChainState } from '../store/lib/ChainState';
import CYCLONEV2Dot2ABI from '../../public/contracts/CycloneV2dot2.json';
import UniswapV2CycloneRouterABI from '../../public/contracts/UniswapV2CycloneRouter.json';

export const IotexMainnetConfig = new ChainState({
  name: 'Iotex Mainnet',
  chainId: 4689,
  rpcUrl: 'https://api.iotex.one:443',
  explorerURL: 'https://iotexscan.io',
  explorerName: 'IotexScan',
  swapName: 'MimoSwap',
  swapURL: 'https://v1.mimo.exchange/swap?source=iotx&dest=io1f4acssp65t6s90egjkzpvrdsrjjyysnvxgqjrh',
  APIURL: 'https://analytics-iotex.cyclone.xyz/query',
  relayer: 'https://iotex-relayer2.cyclone.xyz',
  metas: {
    rewardPerBlock: 5
  },
  Coin: new TokenState({
    symbol: 'IOTX',
    decimals: 18
  }),
  Aeolus: new AeolusContractState({
    deprecated: true,
    name: 'CYC-IOTX LP V1 (Inactive)',
    address: 'io1j2rwjfcm7jt7cwdnlkh0203chlrtfnc59424xc',
    removeLiquidity: 'https://v1.mimo.exchange/remove-liquidity?source=iotx&dest=${token}',
    abi: AeolusV2ABI
  }),
  AeolusV2: new AeolusContractState({
    name: 'CYC-IOTX LP V2',
    address: 'io1rgwa22h66ney8sclpyrdqxwy4n0czjztc2rpu0',
    addLiquidity: 'https://v1.mimo.exchange/add-liquidity?source=iotx&dest=${token}',
    abi: AeolusV2ABI
  }),
  MultiCall: new ContractState({
    address: 'io14n8zjjlh6f0733wxftj9r97ns78ksspmjgzh7e'
  }),
  LPToken: new LPTokenState({
    address: 'io1z2wmt6y74ctc9l4y4ly73gkynp0p4haj2d326m',
    abi: PancakePairABI,
    decimals: 18
  }),
  LPTokenV2: new LPTokenState({
    address: 'io1z2wmt6y74ctc9l4y4ly73gkynp0p4haj2d326m',
    abi: PancakePairABI,
    decimals: 18
  }),
  CYCToken: new TokenState({
    address: 'io1f4acssp65t6s90egjkzpvrdsrjjyysnvxgqjrh',
    abi: XRC20ABI,
    decimals: 18,
    symbol: 'CYC'
  }),
  UniswapV2CycloneRouter: new ContractState({
    address: 'io1cmn68nh06w008knzxye5tp99lpzqat73npjqmh',
    abi: UniswapV2CycloneRouterABI
  }),
  pools: {
    '3': new PoolState({
      id: 3,
      version: 1,
      set: 'Pool 1- Squid',
      address: 'io15w9kwskwl9tn7luhcwrj0rarzjp988pafg07uf',
      abi: CYCLONEABI
    }),
    '4': new PoolState({
      id: 4,
      version: 1,
      set: 'Pool 2- Dolphin',
      address: 'io1wcd67wk36e3r8eku8scv7g7azsfnqs7z3e38xg',
      abi: CYCLONEABI
    }),
    '5': new PoolState({
      id: 5,
      version: 1,
      set: 'Pool 3- Shark',
      address: 'io1v667xgkux8uv0gell53ew5tr090c69k85deezn',
      abi: CYCLONEABI
    }),
    '6': new PoolState({
      id: 6,
      version: 1,
      set: 'Pool 4- Whale',
      address: 'io1wnaks7kectrkxk5v4d7mh97jkqjl4p0690jxfx',
      abi: CYCLONEABI
    }),
    '0': new PoolState({
      id: 0,
      version: 1,
      set: 'Early Sparrow',
      address: 'io1rqm2keejw4jypgl0w6hr96r054ns63u0hqrfuy',
      abi: CYCLONEABI
    }),
    '1': new PoolState({
      id: 1,
      version: 1,
      set: 'Early Raven',
      address: 'io1gkeeljp4grwskgq3tl2xqglqy546nhhmytnqqp',
      abi: CYCLONEABI
    }),
    '2': new PoolState({
      id: 2,
      version: 1,
      set: 'Early Eagle',
      address: 'io1mlcgsv4ma0t6gffpxg2gva3lwqnlj5msradxk6',
      abi: CYCLONEABI
    }),
    '16': new PoolState({
      id: 16,
      version: 2.3,
      set: 'Biscuit - 50,000 IOTX',
      address: 'io13lp2c88eqanyjg3h4y2klyax5s28gfx9q0j4nv',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'IOTX',
      decimals: 18,
      rawRelayerFee: '1000000000000000000'
    }),
    '17': new PoolState({
      id: 17,
      version: 2.3,
      set: 'Snickerdoodle - 500,000 IOTX',
      address: 'io1j2nnwztawyd7cnp3x5vewf2wnrjlp4psz556kv',
      abi: CYCLONEV2Dot2ABI,
      symbol: 'IOTX',
      decimals: 18,
      rawRelayerFee: '1000000000000000000'
    }),
    '18': new PoolState({
      id: 18,
      version: 2.3,
      set: 'Gingersnaps - 2,000,000 VITA',
      address: 'io1jm0rr385aw4vxqkzn4vx2y0s7ja80etexg7ucu',
      XRCToken: new TokenState({
        abi: XRC20ABI,
        address: 'io1hp6y4eqr90j7tmul4w2wa8pm7wx462hq0mg4tw',
        decimals: 18,
        symbol: 'VITA'
      }),
      abi: CYCLONEV2Dot2ABI,
      symbol: 'VITA',
      decimals: 18,
      rawRelayerFee: '1000000000000000000'
    })
  }
});
