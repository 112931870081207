import React, { useEffect, useState } from 'react';
import { observer, useLocalStore, useObserver } from 'mobx-react-lite';
import { Wrapper, Input, FlexBox, Text, Button, Icon, LoadingIcon, Section } from '../../../modules/globalStyle';
import { styled } from '../../../modules/index';
import { useStore } from '../../../store';
import { debounce } from 'lodash';
import { GodUtils, NoteData } from '../../../utils/godUtils';
import { BigNumberState } from '../../../store/type';
import { PoolState } from '../../../store/lib/PoolState';
import BigNumber from 'bignumber.js';
import { hooks } from '../../../lib/hooks';
import axios from 'axios';
import { message } from 'antd';

const DropBox = styled('div', {
  padding: '0.75rem',
  backgroundColor: '$bg5',
  textAlign: 'center',
  '@md': {
    padding: '1.25rem 3.125rem 4rem 3.125rem'
  }
});

let noteInput = null;
let addressInput = null;

export const Withdraw = observer(() => {
  const { lang, god, base, transaction } = useStore();

  const store = useLocalStore(() => ({
    note: '',
    address: '',
    errMsg: '',
    pool: null as PoolState,
    showLoading: false,
    noteData: null as NoteData,
    noteTime: null,
    noteLeafIndex: null,
    subsequent: null,
    showNoteDetail: true,
    depositCoinAmount: new BigNumberState({}),
    poolFee: new BigNumberState({}),

    //v1
    denomination: new BigNumberState({}),
    poolBalance: new BigNumberState({}),
    iotexToBurn: new BigNumberState({}),

    // v2
    relayerFee: new BigNumberState({}),
    cycToWithdraw: new BigNumberState({}),
    coinToWithdraw: new BigNumberState({}),
    depositCYCAmount: new BigNumberState({}),
    depositTokenAmount: new BigNumberState({}),

    get validNote() {
      return !!store.note && !store.errMsg;
    },
    calculateWithdrawA({ numOfShares, maxNumOfShares }) {
      const p = numOfShares / maxNumOfShares;
      if (p >= 1 / 2) {
        return 1 / 2 - p / 2;
      } else if (p >= 1 / 4) {
        return 3 / 4 - p;
      }

      return 1 - 2 * p;
    },

    async handleNote() {
      store.showLoading = true;
      store.note = '';
      await store.changeNote();
      store.note = noteInput.value;
      store.showLoading = false;
    },

    async handleTransaction({ note = store.note }: { note?: string }) {
      const tx = transaction.transactions.find((i) => i.note == note);
      console.log(tx);
      if (tx) {
        tx.status = 3;
        await transaction.save();
      }
    },

    async changeNote() {
      // parse note
      const parsedNote = god.verifyNote(noteInput.value);
      store.noteData = parsedNote;
      if (!parsedNote) {
        store.errMsg = lang.t('invalid');
        return;
      }

      // search pool
      const pool = god.getPool({ poolId: parsedNote.poolId });
      console.log(pool);

      if (!pool) {
        const existsPool = god.findPool({ poolId: parsedNote.poolId });
        console.log({ existsPool });
        if (existsPool) {
          store.errMsg = lang.t('pool.invalid.network', {
            chainName: existsPool.chain.name,
            chainId: existsPool.chain.chainId
          });
        } else {
          store.errMsg = lang.t('invalid');
        }
        return;
      }

      store.pool = pool;
      console.log({ pool });
      const isSpent = await god.isSpent({
        pool,
        nullifier: parsedNote.deposit.nullifierHex
      });
      console.log({ isSpent });

      // check isSpent
      if (isSpent) {
        store.handleTransaction({ note: store.note });
        store.errMsg = lang.t('invalid.spent');
        return;
      }
      const history = await god.currentNetwork.loadPoolLog(pool, { first: 0, force: true });
      const event = history.deposits.find((i) => i.commitment === GodUtils.toHex(parsedNote.deposit.commitment, 32));
      console.log({
        event,
        hex: GodUtils.toHex(parsedNote.deposit.commitment, 32),
        list: history.deposits.map((i) => i.commitment)
      });

      // get note data
      if (event) {
        if (pool.version == 1) {
          const [_apIncentiveRate, _buybackRate, denomination, numOfShares, maxNumOfShares] = await god.currentNetwork.multicall([
            { address: pool.address, abi: pool.abi, method: 'apIncentiveRate' },
            { address: pool.address, abi: pool.abi, method: 'buybackRate' },
            { address: pool.address, abi: pool.abi, method: 'getWithdrawDenomination' },
            { address: pool.address, abi: pool.abi, method: 'numOfShares' },
            { address: pool.address, abi: pool.abi, method: 'maxNumOfShares' }
          ]);
          const a = store.calculateWithdrawA({ numOfShares: numOfShares - 1, maxNumOfShares });
          const apIncentiveRate = Number(_apIncentiveRate || 0) / 10000;
          const buybackRate = Number(_buybackRate || 0) / 10000;

          store.denomination.setValue(new BigNumber(denomination.toString()));
          store.iotexToBurn.setValue(store.denomination.value.multipliedBy(buybackRate).multipliedBy(a));
          store.poolFee.setValue(store.denomination.value.multipliedBy(apIncentiveRate));
          store.poolBalance.setValue(store.denomination.value.minus(store.poolFee.value).minus(store.iotexToBurn.value));
        } else if ([2, 2.2, 2.3].includes(pool.version)) {
          const [cycDenomination, anonymityRate] = await god.currentNetwork.multicall([
            { address: pool.address, abi: pool.abi, method: 'cycDenomination' },
            { address: pool.address, abi: pool.abi, method: pool.anonymityRateMethod }
          ]);
          // const cycDenomination = event.cycDenomination;
          pool.cycDenomination.setValue(new BigNumber(cycDenomination.toString()));
          store.depositCoinAmount.setValue(new BigNumber(event.coinDenomination || event.denomination));
          store.depositTokenAmount.setDecimals(pool.decimals);
          store.depositTokenAmount.setValue(new BigNumber(event.tokenDenomination));
          store.depositCYCAmount.setValue(new BigNumber(event.cycDenomination));
          if (pool.version === 2.2 || pool.version === 2.3) {
            pool.anonymityPoolFee.setValue(new BigNumber(anonymityRate.toString() || 0));
            store.coinToWithdraw.setValue(new BigNumber(event.coinDenomination || event.denomination).minus(pool.relayerFee.value));
            store.poolFee.setValue(new BigNumber(pool.anonymityPoolFee.value));
            store.relayerFee.setValue(pool.relayerFee.value);
            store.cycToWithdraw.setValue(pool.cycDenomination.value);
          } else {
            pool.anonymityPoolFee.setValue(new BigNumber(anonymityRate.toString() || 0).div(10000));
            store.poolFee.setValue(pool.cycDenomination.value.multipliedBy(pool.anonymityPoolFee.value));
            store.relayerFee.setValue(pool.cycDenomination.value.multipliedBy(pool.relayerFee.value.div(10000)));
            store.cycToWithdraw.setValue(pool.cycDenomination.value.minus(store.relayerFee.value).minus(store.poolFee.value));
          }
        }

        store.noteTime = parseInt(event.timestamp) * 1000;
        store.noteLeafIndex = parseInt(event.leafIndex);
        store.subsequent = history.deposits.filter((i) => parseInt(i.timestamp) > parseInt(event.timestamp)).length;
      } else {
        store.errMsg = lang.t('invalid');
        return;
      }

      store.errMsg = null;
    },
    async withdraw() {
      const { noteData, address: recipient, pool } = store;

      base.setLoading({ msg: lang.t('Loading.text4'), showLoading: true, showCancel: true, showConfirm: false });
      const relayerAddress = await god.getRelayer();
      const { args, invalidLeafIndex, invalidRoot, spent, invalidProof } = await god.preWithdraw({
        deposit: noteData.deposit,
        pool,
        recipient,
        relayerAddress
      });

      if (invalidLeafIndex) return base.setLoading({ msg: lang.t(`err.invalidLeafIndex`), showCancel: false });
      if (invalidRoot) return base.setLoading({ msg: lang.t(`err.invalidRoot`), showCancel: false });
      if (invalidProof) return base.setLoading({ msg: lang.t(`err.invalidProof`), showCancel: false });
      if (spent) return base.setLoading({ msg: lang.t(`err.spent`), showCancel: false });
      if (!args) return base.setLoading({ msg: lang.t(`err.note.notfound`), showCancel: false });

      await hooks.waitLoading({ msg: lang.t('Loading.text5'), showCancel: true, showLoading: true });
      const [proof, ...other] = args;

      await axios
        .request({
          url: `${god.currentChain.relayer}/relay`,
          method: 'post',
          data: {
            contract: pool.address,
            proof,
            args: other
          }
        })
        .then(() => {
          base.setLoading({ msg: lang.t('withdraw.result') });
          store.handleTransaction({ note: store.note });
        })
        .catch((err) => message.error(err.response.data.error));
    },

    changeRecipientAddress() {
      if (!god.currentNetwork.isAddressaVailable(addressInput.value)) {
        return (store.address = '');
      }
      store.address = addressInput.value;
    }
  }));

  const withdrawButton = useObserver(() => {
    if (god.provingKey.loading || god.circuit.loading || god.verificationKey.loading) {
      return (
        <Button size="large" color="primary" responsive="large" css={{ margin: '4rem auto 0' }} disabled={true} onClick={store.withdraw}>
          <Text size="small" color="black" weight="semibold" align="center">
            {lang.t('preparing.zksnark')}
          </Text>
        </Button>
      );
    }

    return (
      <Button
        size="large"
        color="primary"
        responsive="large"
        css={{ margin: '4rem auto 0' }}
        disabled={store.note === '' || store.address === ''}
        onClick={store.withdraw}
      >
        <Text size="small" color="black" weight="semibold" align="center">
          {lang.t('Withdraw')}
        </Text>
      </Button>
    );
  });

  useEffect(() => {
    Promise.all([god.readCircuit(), god.readProvingKey(), god.readVerificationKey()]);
  }, []);

  const V1Detail = useObserver(() => {
    return (
      <FlexBox justify="between" css={{ mt: '0.5rem' }} items="start" responsive="rc">
        <Section css={{ width: '100%', '@md': { width: '45%' } }}>
          <FlexBox justify="between" items="start">
            <Text size="md" color="white">
              {lang.t('deposit.amount')}:{' '}
            </Text>
            <Text size="md" color="primary" align="right">
              <div>{store.denomination.format} IOTX</div>
            </Text>
          </FlexBox>
          <FlexBox justify="between">
            <Text size="md" color="white">
              {lang.t('time.passed')}:{' '}
            </Text>
            <Text size="md" color="warning">
              {base.timeAgo.format(new Date(store.noteTime))}
            </Text>
          </FlexBox>
          <FlexBox justify="between">
            <Text size="md" color="white">
              {lang.t('subsequent.deposits')}: {}
            </Text>
            <Text size="md" color="primary">
              {' '}
              {store.subsequent}
            </Text>
          </FlexBox>
        </Section>
        <Section style={{ width: '45%' }}>
          <FlexBox justify="between">
            <Text size="md" color="white">
              {lang.t('pool.fee')}:{' '}
            </Text>
            <Text size="md" color="primary">
              {store.poolFee.format} IOTX
            </Text>
          </FlexBox>
          <FlexBox justify="between">
            <Text size="md" color="white">
              {lang.t('iotex.used')}:{' '}
            </Text>
            <Text size="md" color="primary">
              {store.iotexToBurn.format} IOTX
            </Text>
          </FlexBox>
          <FlexBox justify="between" items="start">
            <Text size="md" color="white">
              {lang.t('balance.to.withdraw')}:{' '}
            </Text>
            <Text size="md" color="primary" align="right">
              <div>{store.poolBalance.format} IOTX</div>
            </Text>
          </FlexBox>
        </Section>
      </FlexBox>
    );
  });

  const V2Detail = useObserver(() => {
    if (store.pool === null) {
      return;
    }
    return (
      <FlexBox justify="between" css={{ mt: '0.5rem' }} items="start" responsive="rc">
        <Section css={{ width: '100%', mb: '0.5rem', '@md': { width: '45%', mb: 0 } }}>
          <FlexBox justify="between" items="start" css={{ mb: '0.5rem' }}>
            <Text size="md" color="white">
              {lang.t('deposit.amount')}:
            </Text>

            <Text size="md" color="primary" align="right">
              {store.depositTokenAmount.value.comparedTo(0) > 0 && (
                <div>
                  {store.depositTokenAmount.format} {store.pool.symbol}
                </div>
              )}
              {store.depositCoinAmount.value.comparedTo(0) > 0 && (
                <div>
                  {store.depositCoinAmount.format} {god.Coin.symbol}
                </div>
              )}
              {store.depositCYCAmount.value.comparedTo(0) > 0 && (
                <div>
                  {store.depositCYCAmount.format} {god.CYCToken.symbol}
                </div>
              )}
            </Text>
          </FlexBox>
          <FlexBox justify="between" css={{ mb: '0.5rem' }}>
            <Text size="md" color="white">
              {lang.t('time.passed')}:
            </Text>
            <Text size="md" color="warning">
              {base.timeAgo.format(new Date(store.noteTime))}
            </Text>
          </FlexBox>
          <FlexBox justify="between">
            <Text size="md" color="white">
              {lang.t('subsequent.deposits')}: {}
            </Text>
            <Text size="md" color="primary">
              {' '}
              {store.subsequent}
            </Text>
          </FlexBox>
        </Section>
        <Section css={{ width: '100%', '@md': { width: '45%' } }}>
          <FlexBox justify="between" css={{ mb: '0.5rem' }}>
            <Text size="md" color="white">
              {lang.t('pool.fee')}:{' '}
            </Text>
            <Text size="md" color="primary">
              {store.poolFee.format} CYC
            </Text>
          </FlexBox>
          <FlexBox justify="between" css={{ mb: '0.5rem' }}>
            <Text size="md" color="white">
              {lang.t('relayer.fee')}:{' '}
            </Text>
            <Text size="md" color="primary">
              {store.relayerFee.format} {store.pool.version === 2.2 || store.pool.version === 2.3 ? god.Coin.symbol : god.CYCToken.symbol}
            </Text>
          </FlexBox>
          <FlexBox justify="between" items="start">
            <Text size="md" color="white">
              {lang.t('amount.to.withdraw')}:{' '}
            </Text>
            <Text size="md" color="primary" align="right">
              {store.depositTokenAmount.value.comparedTo(0) > 0 && (
                <div>
                  {store.depositTokenAmount.format} {store.pool.symbol}
                </div>
              )}
              {store.coinToWithdraw.value.comparedTo(0) > 0 && (
                <div>
                  {store.coinToWithdraw.format} {god.Coin.symbol}
                </div>
              )}
              {store.cycToWithdraw.value.comparedTo(0) > 0 && (
                <div>
                  {store.cycToWithdraw.format} {god.CYCToken.symbol}
                </div>
              )}
            </Text>
          </FlexBox>
        </Section>
      </FlexBox>
    );
  });

  const loadingText = (
    <FlexBox css={{ height: '126px' }} justify="center">
      <LoadingIcon />
      <Text size="lg" color="primary" weight="medium">
        {lang.t('Loading.text8')}
      </Text>
    </FlexBox>
  );

  return (
    <Wrapper>
      <DropBox>
        <Text size="lg" color="white" weight="medium" css={{ mb: '0.5rem' }}>
          {lang.t('note')}
        </Text>
        <Section css={{ position: 'relative' }}>
          <Input
            ref={(node) => (noteInput = node)}
            placeholder="Please enter your note"
            onChange={debounce(store.handleNote, 0)}
            type={store.showNoteDetail ? 'text' : 'password'}
            css={{ pr: '2rem' }}
          />
          <Icon
            src={store.showNoteDetail ? '/images/home/eye-open.png' : '/images/home/eye-off.png'}
            onClick={() => (store.showNoteDetail = !store.showNoteDetail)}
            css={{
              position: 'absolute',
              right: '1rem',
              top: 9
            }}
          ></Icon>
        </Section>
        {store.errMsg && (
          <Text size="md" color="warning" weight="medium" css={{ mt: '1.125rem' }}>
            {store.errMsg}
          </Text>
        )}
        {store.showLoading ? (
          loadingText
        ) : (
          <>
            {store.validNote && store.pool.version == 1 && V1Detail}
            {store.validNote && [2, 2.2, 2.3].includes(store.pool.version) && V2Detail}
          </>
        )}

        <Text size="lg" color="white" weight="medium" css={{ mb: '0.5rem', mt: '3.75rem' }}>
          {lang.t('recipient.address')}
        </Text>
        <Text size="md" color="warning" weight="medium" css={{ mb: '1.125rem' }}>
          {lang.t('recipient.tips')}
        </Text>
        <Input ref={(node) => (addressInput = node)} placeholder="Please paste address here" onChange={debounce(store.changeRecipientAddress, 0)} />
        {withdrawButton}
      </DropBox>
    </Wrapper>
  );
});
